<template lang="pug">
b-modal(v-model='isModalActive' has-modal-card trap-focus aria-role='dialog' aria-modal :can-cancel='["x"]')
  modal-card.assign-devices-body(v-if='userSelected' :title='`Asignar equipos a ${userSelected.names}`')
    app-form(@submit.prevent='submit' :is-saving='saving' autocomplete='off')
      devices-field(v-model='selectedDevices')
</template>

<script>
import toastService from '@/services/toast.service'
import usersService from '@/services/users.service'
import DevicesField from '@/components/shared/forms/DevicesField'

export default {
  components: { DevicesField },
  props: {
    value: { type: Boolean },
    userSelected: { type: Object }
  },
  data () {
    return {
      isModalActive: false,
      saving: false,
      selectedDevices: this.userSelected?.userDevices || []
    }
  },
  created () {
    this.isModalActive = this.value
  },
  watch: {
    value () {
      this.isModalActive = this.value
    },
    userSelected () {
      this.selectedDevices = this.userSelected?.userDevices || []
    },
    isModalActive () {
      this.$emit('input', this.isModalActive)
    }
  },
  methods: {
    async submit ($event) {
      this.saving = true
      const result = await usersService.assignDevices(this.userSelected.id, this.selectedDevices)
      if (result) {
        this.$emit('saved', $event)
        toastService.show('Equipos actualizados')
        this.isModalActive = false
      }
      this.saving = false
    }
  }
}
</script>

<style lang="scss" scoped>
.assign-devices-body {
  min-height: 300px;
}
</style>
