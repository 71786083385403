<template lang="pug">
app-form(@submit.prevent='submit' :is-saving='saving' autocomplete='off')
  field(label='Nombre del cliente' field='clientName' required)
    input.input(type='text' placeholder='Nombre del cliente' maxlength='50' required v-model='client.name')
  b-button(
    v-if='!isEdit'
    type='is-ghost'
    size='is-small'
    icon-pack='fa'
    :icon-left='showOptionalFields.client ? "chevron-up" : "chevron-down"'
    @click="toggleClientFields"
  )
    | {{ showOptionalFields.client ? 'Ocultar otros campos' : 'Mostrar otros campos' }}
  b-collapse(:open="showOptionalFields.client" @open="toggleClientFields" animation="slide")
    field(label='Tipo de documento' field='identificationType')
      app-select(id='identificationType' :items='docTypesItems' v-model='client.identificationType')
    field(label='Identificación' field='clientIdentification')
      input.input(type='text' placeholder='Identification del cliente' maxlength='100' v-model='client.identification')
    field(label='Teléfono' field='clientPhone')
      input.input(type='text' placeholder='Teléfono del cliente' maxlength='100' v-model='client.phone')
    field(label='Email' field='clientEmail')
      input.input(type='email' placeholder='Email del cliente' maxlength='100' v-model='client.email')
    field(label='Dirección' field='clientAddress')
      input.input(type='text' placeholder='Dirección del cliente' maxlength='100' v-model='client.address')
  hr
  div.mb(v-if='!isEdit')
    b-message
      | Debes crear una cuenta de usuario para este cliente
    field(label='Nombre usuario' field='userName' required)
      input.input(type='text' placeholder='Nombre y apellido' maxlength='30' required v-model='user.names')
    field(label='Email' field='userEmail' required)
      input.input(type='email' placeholder='Email del usuario' maxlength='100' required v-model='user.email')
    field(label='UTC' field='userUTC' required)
      input.input(type='number' step='1' placeholder='UTC' min='-11' max='11' autocomplete='off' required v-model.number='user.utc')
    b-button(
      type='is-ghost'
      size='is-small'
      icon-pack='fa'
      :icon-left='showOptionalFields.user ? "chevron-up" : "chevron-down"'
      @click="toggleUserFields"
    )
      | {{ showOptionalFields.user ? 'Ocultar otros campos' : 'Mostrar otros campos' }}
    b-collapse(:open="showOptionalFields.user" animation="slide")
      field(label='Alias' field='userAlias')
        input.input(type='text' placeholder='Alias del usuario' maxlength='50' v-model='user.alias')
      field(label='Contraseña' field='userPassword')
        input.input(type='password' placeholder='Contraseña' autocomplete='off' v-model.number='user.password')
      field(label='Fecha de expiración' field='userExpireOn')
        app-date(id='expireOn' v-model='user.expireOn')
</template>

<script>
import clientsService from '@/services/clients.service'
import toastService from '@/services/toast.service'
import authMixin from '@/mixins/auth.mixin'
import { docTypesItems } from '@/views/clients/constants'

export default {
  mixins: [authMixin],
  props: {
    clientSelected: { type: Object }
  },
  data () {
    return {
      client: {},
      user: {},
      saving: false,
      docTypesItems,
      showOptionalFields: {
        client: false,
        user: false
      }
    }
  },
  created () {
    if (this.$parent.setTitle) {
      this.$parent.setTitle('Crear cliente')
    }
    this.client = this.clientSelected ? { ...this.clientSelected } : {}
    this.user = { ...this.user, utc: this.currentUser.utc || 0 }
    this.showOptionalFields.client = this.isEdit
  },
  computed: {
    isEdit () {
      return !!this.client.id
    }
  },
  methods: {
    toggleClientFields () {
      this.showOptionalFields.client = !this.showOptionalFields.client
    },
    toggleUserFields () {
      this.showOptionalFields.user = !this.showOptionalFields.user
    },
    async submit () {
      this.saving = true
      const users = this.isEdit ? {} : {
        users: [{
          ...this.user,
          active: true,
          alias: this.user?.alias || this.user?.email,
          password: String(this.user?.password || '')
        }]
      }
      const client = { ...this.client, ...users }
      const result = await clientsService.save(client)
      if (result) {
        toastService.show('Cliente guardado')
        this.$parent.close()
        this.$emit('saved')
      }
      this.saving = false
    }
  }
}
</script>
