<template lang="pug">
b-tab-item(label='Límites de uso')
  .usage-limits-grid.mt
    span
    b  Uso
    b  Cantidad permitida
    b  Disponibles para agregar
    template(v-for='usage in usageLimits')
      b  {{ usage.label }}
      span  {{ `${usage.used} de ${usage.allowed}` }}
      b-field
        b-numberinput(
          v-model='usage.allowed'
          :min='usage.min'
          :max='usage.max'
          @input='onInput(usage.key)'
          controls-position='compact'
          expanded
          controls-controls-rounded
        )
      span {{ usage.available }}
  b-button.mt-5(type='is-primary' :loading='isSaving' @click='onSave') Guardar
</template>

<script>
import clientsService from '@/services/clients.service'
import toastService from '@/services/toast.service'

export default {
  props: {
    client: { type: Object, required: true }
  },
  data () {
    return {
      isSaving: false,
      usageLimits: {
        Locations: { key: 'Locations', label: 'Sitios', allowed: 0, used: 0, available: 0, min: 0, max: 0 },
        Zones: { key: 'Zones', label: 'Zonas', allowed: 0, used: 0, available: 0, min: 0, max: 0 },
        Rules: { key: 'Rules', label: 'Reglas', allowed: 0, used: 0, available: 0, min: 0, max: 0 }
        // Viewers: { key: 'Viewers', label: 'Visores', allowed: 0, used: 0, available: 0, min: 0, max: 0 }
      }
    }
  },
  created () {
    Object.keys(this.usageLimits).forEach(key => {
      this.usageLimits[key].allowed = this.client[`allowed${key}`]
      this.usageLimits[key].used = this.client[`used${key}`]
      this.usageLimits[key].available = this.client[`available${key}`]
      this.usageLimits[key].min = this.client[`used${key}`]
      this.usageLimits[key].max = this.client[`allowed${key}`] + this.client[`available${key}`]
    })
  },
  methods: {
    onInput (usageKey) {
      const usageObj = this.usageLimits[usageKey]
      this.usageLimits = {
        ...this.usageLimits,
        [usageKey]: { ...usageObj, available: usageObj.max - usageObj.allowed }
      }
    },
    async onSave () {
      this.isSaving = true
      const client = {
        ...this.client,
        ...Object
          .keys(this.usageLimits)
          .reduce((acc, key) => {
            return { ...acc, [`allowed${key}`]: this.usageLimits[key].allowed }
          }, {})
      }
      const result = await clientsService.save(client)
      if (result) {
        toastService.show('Límites de uso actualizados')
      }
      this.isSaving = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/variables/colors';

.usage-limits-grid {
  border-bottom: 1px solid $border-gray;
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr 1fr 2fr 2fr;
  grid-template-rows: auto;
  max-width: 900px;
}
</style>
