<template lang="pug">
.container
  page-title(:title='client.id ? `Cliente / [ID: ${client.id}] ${client.name}` : "Cliente"')
    button.button(@click='editClient')
      icon(icon='edit')
  b-loading(is-full-page :active='loading')
  .card(v-if='!loading')
    .card-content
      b-tabs(destroy-on-hide)
        client-info-tab(:client='client')
        notes-tab(:client='client')
        users-tab(:client-id='client.id')
        usage-limits-tab(:client='client')
        devices-tab(:devices='devices')
  client-form-modal(v-model='clientFormModalActive' :client-selected='client || {}' @saved='getClient')
</template>

<script>
import clientsService from '@/services/clients.service'
import ClientFormModal from '@/components/clients/ClientFormModal'
import ClientInfoTab from './blocks/ClientInfoTab'
import UsersTab from './blocks/UsersTab'
import UsageLimitsTab from './blocks/UsageLimitsTab'
import DevicesTab from './blocks/DevicesTab'
import { clientsData } from './constants'
import { clientMapper } from './utils'
import NotesTab from './blocks/NotesTab.vue'

export default {
  components: { ClientFormModal, ClientInfoTab, UsersTab, UsageLimitsTab, DevicesTab, NotesTab },
  data () {
    return {
      client: {},
      loading: false,
      clientsData,
      clientFormModalActive: false
    }
  },
  created () {
    this.getClient()
  },
  computed: {
    devices () {
      return this.client?.devices || []
    }
  },
  methods: {
    async getClient () {
      this.loading = true
      const id = this.$route?.params?.id
      if (id) {
        const response = await clientsService.getOne(id)
        this.client = clientMapper(response)
        this.loading = false
      }
    },
    editClient () {
      this.clientFormModalActive = true
    }
  }
}
</script>
