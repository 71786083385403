<template lang="pug">
b-tab-item(label='Equipos')
  custom-table(
    class='devices-grid'
    id='devices'
    :column-defs='devicesColumnDefs'
    :row-data='devices'
    row-data-key='id'
  )
</template>

<script>
import CustomTable from '@/components/CustomTable'
import { devicesColumnDefs } from '../constants'

export default {
  components: { CustomTable },
  props: {
    devices: { type: Array, required: true }
  },
  data () {
    return {
      devicesColumnDefs
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
</style>
