export const clientStates = {
  active: 'Activos',
  inactive: 'Inactivos'
}

export const labelBtnByStates = {
  [clientStates.active]: 'Inactivar',
  [clientStates.inactive]: 'Activar'
}

export const clientsColumnDefs = [
  { sortable: true, filter: true, field: 'id', headerName: 'Id', type: 'link' },
  { sortable: true, filter: true, field: 'name', headerName: 'Nombre' },
  { sortable: true, filter: true, field: 'phone', headerName: 'Teléfono' },
  { sortable: true, filter: true, field: 'email', headerName: 'Email' },
  { sortable: true, filter: true, field: 'identificationType', headerName: 'Tipo Documento' },
  { sortable: true, filter: true, field: 'identification', headerName: 'Identificación' },
  { sortable: true, filter: true, field: 'address', headerName: 'Dirección' },
  { sortable: true, filter: true, field: 'locations', headerName: 'Sitios' },
  { sortable: true, filter: true, field: 'zones', headerName: 'Zonas' },
  { sortable: true, filter: true, field: 'rules', headerName: 'Reglas' },
  { sortable: true, filter: true, field: 'viewers', headerName: 'Visores' },
  { sortable: true, filter: true, field: 'enabledUserAccounts', headerName: 'Usuarios activos' }
]

export const usersColumnDefs = [
  { sortable: true, filter: true, field: 'id', headerName: 'Id' },
  { sortable: true, filter: true, field: 'names', headerName: 'Nombre' },
  { sortable: true, filter: true, field: 'alias', headerName: 'Alias' },
  { sortable: true, filter: true, field: 'email', headerName: 'Email' },
  { sortable: true, filter: true, field: 'utc', headerName: 'UTC' },
  { sortable: true, filter: true, field: 'activeLabel', headerName: 'Activo' },
  { sortable: true, filter: true, field: 'userDevicesNames', headerName: 'Equipos asignados' },
  { sortable: true, filter: true, field: 'expireOnFormatted', headerName: 'Fecha de expiración' }
]

export const devicesColumnDefs = [
  { sortable: true, filter: true, field: 'id', headerName: 'Id' },
  { sortable: true, filter: true, field: 'name', headerName: 'Nombre' },
  { sortable: true, filter: true, field: 'imei', headerName: 'IMEI' },
  { sortable: true, filter: true, field: 'billableLabel', headerName: 'Facturable' },
  { sortable: true, filter: true, field: 'odometer', headerName: 'Odometro' },
  { sortable: true, filter: true, field: 'group', headerName: 'Agrupador' },
  { sortable: true, filter: true, field: 'porpuse', headerName: 'Proposito' },
  { sortable: true, filter: true, field: 'registerDateFormatted', headerName: 'Fecha de registro' },
  { sortable: true, filter: true, field: 'activeDateFormatted', headerName: 'Fecha de activación' }
]

export const docTypesItems = [
  { id: 'CC', name: 'Cédula' },
  { id: 'NIT', name: 'NIT' },
  { id: 'P', name: 'Pasaporte' },
  { id: 'TI', name: 'Tarjeta de identidad' },
  { id: 'TE', name: 'Tarjeta de extranjería' },
  { id: 'CE', name: 'Cedula de extranjería' }
]

export const clientsData = [
  { field: 'active', label: 'Estado', renderFn: value => value ? 'Activo' : 'Inactivo' },
  { field: 'identificationType', label: 'Tipo de documento', renderFn: value => docTypesItems.find(item => item.id === value)?.name || value },
  { field: 'identification', label: 'Identificación' },
  { field: 'name', label: 'Nombre' },
  { field: 'phone', label: 'Teléfono' },
  { field: 'address', label: 'Dirección' },
  { field: 'email', label: 'Email' }
]
