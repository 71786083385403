import dateTimeFilter from '@/filters/datetime.filter'

export const clientMapper = client => {
  return {
    ...client,
    users: usersMapper(client.users),
    devices: devicesMapper(client.devices)
  }
}

export const usersMapper = users => {
  return users.map(user => {
    const userDevicesNames = (user.userDevices || []).map(device => device.deviceName).join(' | ')
    return {
      ...user,
      activeLabel: user.active ? 'Sí' : 'No',
      expireOnFormatted: dateTimeFilter(user.expireOn),
      userDevicesNames: `(${(user.userDevices || []).length}) ${userDevicesNames}`
    }
  })
}

export const devicesMapper = devices => {
  return devices.map(device => {
    return {
      ...device,
      billableLabel: device.billable ? 'Sí' : 'No',
      registerDateFormatted: dateTimeFilter(device.registerDate),
      activeDateFormatted: dateTimeFilter(device.activeDate)
    }
  })
}
