<template lang="pug">
b-tab-item(label='Notas')
  notes-widget(type='CLIENT' :type-id='client.id' v-if='client && client.id')
</template>

<script>
import NotesWidget from '@/components/notes/NotesWidget.vue'

export default {
  components: { NotesWidget },
  props: {
    client: { type: Object }
  }
}
</script>
