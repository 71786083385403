<template lang="pug">
b-modal(v-model='isModalActive' has-modal-card trap-focus aria-role='dialog' aria-modal :can-cancel='["x"]')
  modal-card(v-if='userSelected' title='Crear usuario' width='500px')
    user-form(:user-selected='userSelected' @saved='saved')
</template>

<script>
import UserForm from './UserForm'

export default {
  components: { UserForm },
  props: {
    value: { type: Boolean },
    userSelected: { type: Object }
  },
  data () {
    return {
      isModalActive: false
    }
  },
  created () {
    this.isModalActive = this.value
  },
  watch: {
    value () {
      this.isModalActive = this.value
    },
    isModalActive () {
      this.$emit('input', this.isModalActive)
    }
  },
  methods: {
    saved ($event) {
      this.$emit('saved', $event)
    }
  }
}
</script>
