<template lang="pug">
b-tab-item
  template(slot='header')
    span Usuarios
      b-tag(rounded) {{ usersCount }}
  div
    .actions-container
      div.flex
        button.button.is-primary(type='button' @click='create')
          | Nuevo usuario
        b-button.ml(@click='editSelected' v-if='selected && selected.id' type='is-primary' outlined)
          | Editar
        b-button.ml(@click='resetPassword' v-if='selected && selected.id' type='is-primary' outlined)
          | Resetear clave
        b-button.ml(@click='assignDevices' v-if='selected && selected.id' type='is-primary' outlined)
          | Equipos asignados
    custom-table(
      class='users-grid'
      id='users'
      :column-defs='usersColumnDefs'
      :row-data='users'
      row-data-key='id'
      @row-selected='userSelected'
    )
    user-form-modal(v-model='userFormModalActive' :user-selected='selected || {}' @saved='getUsers')
    user-assign-devices-modal(v-model='assignDevicesModalActive' :user-selected='selected || {}' @saved='getUsers')
</template>

<script>
import CustomTable from '@/components/CustomTable'
import UserFormModal from '@/components/users/UserFormModal'
import UserAssignDevicesModal from '@/components/users/UserAssignDevicesModal'
import usersService from '@/services/users.service'
import { usersColumnDefs } from '../constants'
import { usersMapper } from '../utils'
import toastService from '@/services/toast.service'

export default {
  components: { CustomTable, UserFormModal, UserAssignDevicesModal },
  props: {
    clientId: { type: Number, required: true }
  },
  data () {
    return {
      loading: false,
      usersColumnDefs,
      users: [],
      selected: null,
      userFormModalActive: false,
      assignDevicesModalActive: false
    }
  },
  created () {
    this.getUsers()
  },
  computed: {
    usersCount () {
      return this.users.length
    }
  },
  methods: {
    async getUsers () {
      this.loading = true
      const id = this.$route?.params?.id
      if (id) {
        const response = await usersService.getAll(this.clientId || 0)
        this.users = usersMapper(response)
        this.loading = false
      }
    },
    userSelected (row) {
      this.selected = row.rowData ? { ...row.rowData } : null
    },
    create () {
      this.selected = { clientId: this.clientId }
      this.userFormModalActive = true
    },
    editSelected () {
      this.userFormModalActive = true
    },
    assignDevices () {
      this.assignDevicesModalActive = true
    },
    async resetPassword () {
      if (await toastService.confirmWarning(`¿Desea resetear la clave a ${this.selected.names}? Una vez se haga, el usuario deberá iniciar sesión sin clave y el sistema le solicitará una nueva`)) {
        const result = await usersService.resetPassword(this.selected.id)
        if (result) {
          toastService.show('Clave reseteada')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.actions-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
</style>
