<template lang="pug">
vue-editable-grid(
  :class='styleClass'
  :id='id'
  :column-defs='columnDefs'
  :row-data='rowData'
  :row-data-key='rowDataKey'
  :enable-filters='enableFilters'
  @row-selected='$emit("row-selected", $event)'
  @link-clicked='$emit("link-clicked", $event)'
)
</template>

<script>
export default {
  props: {
    columnDefs: { type: Array, required: true },
    enableFilters: { type: Boolean, default: true },
    id: { type: String, required: true },
    rowData: { type: Array, required: true },
    rowDataKey: { type: String, required: true },
    styleClass: { type: String, default: '' }
  }
}
</script>
