<template lang="pug">
b-tab-item(label='Datos del cliente')
  property-list.mb-3(:data='client' :properties='clientsData' type='table' default-value='[Sin información]')
</template>

<script>
import PropertyList from '@/components/PropertyList'
import { clientsData } from '../constants'

export default {
  components: { PropertyList },
  props: {
    client: { type: Object, required: true }
  },
  data () {
    return {
      clientsData
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
</style>
