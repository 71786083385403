<template lang="pug">
app-form(@submit.prevent='submit' :is-saving='saving' autocomplete='off')
  field(label='Nombre usuario' field='userName' required)
    input.input(type='text' placeholder='Nombre y apellido' maxlength='30' required v-model='user.names')
  field(label='Email' field='userEmail' required)
    input.input(type='email' placeholder='Email del usuario' maxlength='100' required v-model='user.email')
  field(label='UTC' field='userUTC' required)
    input.input(type='number' step='1' placeholder='UTC' min='-11' max='11' autocomplete='off' required v-model.number='user.utc')
  b-button(
    v-if='!isEdit'
    type='is-ghost'
    size='is-small'
    icon-pack='fa'
    :icon-left='showOptionalFields ? "chevron-up" : "chevron-down"'
    @click="toggleUserFields"
  )
    | {{ showOptionalFields ? 'Ocultar otros campos' : 'Mostrar otros campos' }}
  b-collapse(:open="showOptionalFields" animation="slide")
    field(label='Alias' field='userAlias')
      input.input(type='text' placeholder='Alias del usuario' maxlength='50' v-model='user.alias')
    field(label='Contraseña' field='userPassword')
      input.input(type='password' placeholder='Contraseña' autocomplete='off' v-model.number='user.password')
    field(label='Fecha de expiración' field='userExpireOn')
      app-date(id='expireOn' v-model='user.expireOn')
    field(v-if='isEdit' label='Activo' field='active')
      input(type='checkbox' v-model.number='user.active')
</template>

<script>
import authMixin from '@/mixins/auth.mixin'
import usersService from '@/services/users.service'
import toastService from '@/services/toast.service'

export default {
  mixins: [authMixin],
  props: {
    userSelected: { type: Object }
  },
  data () {
    return {
      user: {},
      saving: false,
      showOptionalFields: false
    }
  },
  created () {
    if (this.$parent.setTitle) {
      this.$parent.setTitle('Crear usuario')
    }
    const utc = this.currentUser.utc || 0
    this.user = this.userSelected ? { ...this.userSelected, utc } : { utc }
    this.showOptionalFields = this.isEdit
  },
  computed: {
    isEdit () {
      return !!this.user.id
    }
  },
  methods: {
    toggleUserFields () {
      this.showOptionalFields = !this.showOptionalFields
    },
    async submit () {
      this.saving = true
      const user = {
        ...this.user,
        active: this.isEdit ? this.user.active : true,
        alias: this.user?.alias || this.user?.email,
        password: String(this.user?.password || '')
      }
      const result = await usersService.save(user)
      if (result) {
        toastService.show('Usuario guardado')
        this.$parent.close()
        this.$emit('saved')
      }
      this.saving = false
    }
  }
}
</script>
